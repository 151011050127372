import request from '@/utils/request'

/** 文件上传 */
export function getuploadtoken() {
  return request({
    url: 'https://xcx.newacademic.net/admin/task/ststoken/',
    method: 'get'
  })
}

// ocr识别
export function uploadocr(data) {
  return request({
    url: 'https://xcx.newacademic.net/api/literature/ImageOcr/',
    method: 'POST',
    headers:{
      "Content-Type": "multipart/form-data",
    },
    data
  })
}

// export function checkUpload(data) {
//   return request({
//     url: 'task/submittask/',
//     method: 'get',
//     params: data
//   })
// }
